<template>
  <div class="app-main-content">
    <div class="content">
      <div class="content-left">
        <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="基础信息">
            <div class="title" style="margin-bottom: 24px;">项目公告</div>
            <edp-textarea
              v-if="hasSettingP"
              v-model="info.project_notice"
              :db="dbs.prjCore"
              field-name="project_notice"
              :oid="info.oid"
              :prj-oid="$route.query.prj_oid"
              disabled-auto-update
              ref="project_notice"
              @change="reload"
            >
            </edp-textarea>
            <div class="tab_list_cont" v-else>{{info.project_notice}}</div>
            <a-button
                @click="$refs.project_notice.updateField()"
                type="primary" class="savebtn">
              保存
            </a-button>
            <div class="title">基础信息</div>
            <div class="tab_list">
              <div class="tab_listcont">
                <div class="tab_list_item">
                  <div class="tab_list_name">项目名称</div>
                  <div class="tab_list_cont">
                    <edp-input
                      v-if="hasSettingP"
                      v-model="info.project_name"
                      field-name="project_name"
                      :prj-oid="$route.query.prj_oid"
                      :oid="info.oid"
                      :db="dbs.prjCore"
                      @change="reload"
                    ></edp-input>
                    <template v-else>{{info.project_name}}</template>
                  </div>
                </div>
                <div class="tab_list_item">
                  <div class="tab_list_name">项目编号</div>
                  <div class="tab_list_cont">
                    <edp-input
                      v-if="hasSettingP"
                      v-model="info.project_code"
                      field-name="project_code"
                      :prj-oid="$route.query.prj_oid"
                      :oid="info.oid"
                      :db="dbs.prjCore"
                      @change="reload"
                    ></edp-input>
                    <template v-else>{{info.project_code}}</template>
                  </div>
                </div>
              </div>
              <div class="tab_listcont">
                <div class="tab_list_item">
                  <div class="tab_list_name">项目类型</div>
                  <div class="tab_list_cont">
                    <edp-select
                      v-if="hasSettingP"
                      v-model="info.project_type"
                      field-name="project_type"
                      :prj-oid="$route.query.prj_oid"
                      :oid="info.oid"
                      :db="dbs.prjCore"
                      cat-type="project_type"
                      @change="reload"
                    ></edp-select>
                    <template v-else>{{getCatNameByCode(info.project_type, 'project_type')}}</template>
                  </div>
                </div>
              </div>
              <div class="tab_listcont">
                <div class="tab_list_item">
                  <div class="tab_list_name">项目状态</div>
                  <div class="tab_list_cont">
                    <edp-select
                      v-if="hasSettingP"
                      v-model="info.business_status"
                      field-name="business_status"
                      :prj-oid="$route.query.prj_oid"
                      :oid="info.oid"
                      :db="dbs.prjCore"
                      cat-type="project_bstatus"
                      @change="reload"
                    ></edp-select>
                    <template v-else>{{getCatNameByCode(info.business_status, 'project_bstatus')}}</template>
                  </div>
                </div>
              </div>
              <div class="tab_listcont">
                <div class="tab_list_item">
                  <div class="tab_list_name">项目负责人</div>
                  <div class="tab_list_cont">
                    <edp-select
                      v-if="hasSettingP"
                      showSearch
                      v-model="info.project_admin"
                      field-name="project_admin"
                      :prj-oid="$route.query.prj_oid"
                      :oid="info.oid"
                      :db="dbs.prjCore"
                      account="all"
                      @change="reload"
                    ></edp-select>
                    <template v-else>{{info.admins?.[0]?.name}}</template>
                  </div>
                </div>
              </div>
            </div>
            <div class="title">项目属性信息</div>
            <div class="tab_list">
              <div class="tab_listcont" v-if="info.creator_name">
                <div class="tab_list_item" style="margin-top: 12px">
                  <div class="tab_list_name">创建者</div>
                  <div class="tab_list_cont">
                    <img :src="info.creator_pic" alt="">
                    <span class="name">{{
                        info.creator_title ? info.creator_name + '-' + info.creator_title : info.creator_name
                      }}</span>
                  </div>
                </div>
              </div>
              <div class="tab_listcont">
                <div class="tab_list_item">
                  <div class="tab_list_name">创建时间</div>
                  <div class="tab_list_cont">{{ info.create_time }}</div>
                </div>
              </div>
            </div>
            <template v-if="$hasP('R_FINANCE')">
              <div class="title">财务信息</div>
              <div class="tab_list">
                <div class="tab_listcont">
                  <div class="tab_list_item">
                    <div class="tab_list_name">计划收入总额</div>
                    <div class="tab_list_cont">
                      <edp-input
                          v-if="hasSettingP"
                          number
                          v-model="info.schedule_income"
                          field-name="schedule_income"
                          :prj-oid="$route.query.prj_oid"
                          :oid="info.oid"
                          :db="dbs.prjCore"
                          @change="reload"
                      ></edp-input>
                      <template v-else>{{info.schedule_income}}</template>
                    </div>
                  </div>
                  <div class="tab_list_item">
                    <div class="tab_list_name">实际收入总额</div>
                    <div class="tab_list_cont">
                      {{ info.actual_income | numberFormat }}
                    </div>
                  </div>
                </div>
                <div class="tab_listcont">
                  <div class="tab_list_item">
                    <div class="tab_list_name">计划支出总额</div>
                    <div class="tab_list_cont">
                      <edp-input
                          v-if="hasSettingP"
                          number
                          v-model="info.schedule_outcome"
                          :db="dbs.prjCore"
                          field-name="schedule_outcome"
                          :oid="info.oid"
                          :prj-oid="$route.query.prj_oid"
                          @change="reload"
                      />
                      <template v-else>{{info.schedule_outcome}}</template>
                    </div>
                  </div>
                  <div class="tab_list_item">
                    <div class="tab_list_name">实际支出总额</div>
                    <div class="tab_list_cont">
                      {{ info.actual_outcome | numberFormat }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="title">工时信息</div>
            <div class="tab_list">
              <div class="tab_listcont">
                <div class="tab_list_item" style="margin-top: 12px">
                  <div class="tab_list_name">预估工时</div>
                  <div class="tab_list_cont">
                    <edp-input
                        style="width: 60px;"
                        v-model="info.schedule_worktime"
                        :db="dbs.prjCore"
                        field-name="schedule_worktime"
                        :oid="info.oid"
                        :prj-oid="info.oid"
                        @change="getInfo"
                    >
                    </edp-input>
                    小时
                  </div>
                </div>
              </div>
              <div class="tab_listcont">
                <div class="tab_list_item">
                  <div class="tab_list_name">已登记工时</div>
                  <div class="tab_list_cont">{{ info.actual_worktime || '0'}}小时</div>
                </div>
              </div>
              <div class="tab_listcont">
                <div class="tab_list_item">
                  <div class="tab_list_name">剩余工时</div>
                  <div class="tab_list_cont">{{ info.worktime_left || '0' }}小时</div>
                </div>
              </div>
              <div class="tab_listcont">
                <div class="tab_list_item">
                  <div class="tab_list_name">预估偏差</div>
                  <div class="tab_list_cont">{{ info.deviation || '0' }}小时</div>
                </div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="项目介绍">
            <div class="title" style="margin-bottom: 15px;">项目介绍</div>
            <edp-textarea
              v-if="hasSettingP"
              disabled-auto-update
              ref="project_intro"
              v-model="info.project_intro"
              :db="dbs.prjCore"
              field-name="project_intro"
              :oid="info.oid"
              :prj-oid="$route.query.prj_oid"
              @change="reload"
            ></edp-textarea>
            <div v-else>{{info.project_intro}}</div>
            <a-button
              v-if="hasSettingP"
              @click="$refs.project_intro.updateField()"
              type="primary" class="savebtn">
              保存
            </a-button>
            <div class="title">项目文件</div>
            <div style="width: 700px">
              <attachment-upload :source-oid="$route.query.prj_oid" source-type="0"
                                 :prj-oid="$route.query.prj_oid"></attachment-upload>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="content-right">
        <a-tabs>
          <a-tab-pane :key="1" tab="动态">
            <bizlog ref="bizlog" :source-oid="$route.query.prj_oid"></bizlog>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import catMixin from "@/utils/mixins/catMixin.js";
import {fetch} from "@/utils/request.js";
import Bizlog from "@/components/bizlog.vue";
import AttachmentUpload from "@/components/attachmentUpload.vue";
import {FieldUpdateDb} from "@/utils/FieldUpdate.js";
import EdpInput from "@/components/e-form/edp-input.vue";
import EdpSelect from "@/components/e-form/edp-select.vue";
import EdpTextarea from "@/components/e-form/edp-textarea.vue";

export default {
  name: "projectPlanDeliverable",
  components: { EdpTextarea, EdpSelect, EdpInput, AttachmentUpload, Bizlog },
  mixins: [catMixin],
  data() {
    return {
      dbs: {
        prjCore: new FieldUpdateDb("prj", "prj_core")
      },
      cat: {
        project_type: [],
        project_bstatus: []
      },
      info: {},
    }
  },
  created() {
    this.getInfo()
  },
  computed: {
    hasSettingP() {
      if (this.$hasP("P_SETTING")) {
        return true
      } else {
        let useroid = JSON.parse(localStorage.getItem('company_info')).account_oid
        return this.info.admins?.find(item => item.oid === useroid) || this.info.creator_oid === useroid
      }
    }
  },
  methods: {
    getInfo() {
      fetch('POST', '/prj/info', {
        oid: this.$route.query.prj_oid
      }).then((res) => {
        console.log('res', res)
        if (res.status === 0) {
          this.info = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    reload() {
      this.$refs.bizlog.getBizLogList()
    }
  }
}
</script>

<style scoped lang="less">
.app-main-content {
  padding: 0 7px;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    overflow: hidden;

    .content-left {
      flex: 1;
      height: 100%;
      padding: 0 23px;
      background-color: #FFF;
      overflow-y: auto;
      font-weight: 500;
      font-size: 14px;
      color: #303030;
      box-shadow: 0 2px 10px 0 rgba(182, 182, 182, 0.50);

      /deep/ .ant-tabs-bar {
        margin: 0;
      }

      .title {
        line-height: 1;
        font-weight: 500;
        font-size: 16px;
        color: #303030;
        margin-top: 30px;
      }

      .textarea {
        margin-top: 10px;
      }

      .savebtn {

        margin-top: 14px;
      }

      .tab_list {
        display: flex;
        width: 100%;
        --fontWeight: 500;

        .tab_listcont {
          width: 25%;

          .tab_list_item {
            margin-top: 12px;
            width: calc(100% - 20px);
            display: flex;
            font-weight: 500;
            align-items: center;
            font-size: 14px;
            color: #303030;
            --width: 100%;

            .tab_list_name {
              width: 100px;
              flex-shrink: 0;
            }

            .tab_list_cont {
              display: flex;
              align-items: center;
              flex: 1;
              min-height: 32px;
              img {
                width: 21px;
                height: 21px;
                border-radius: 50%;
              }

              .name {
                font-size: 14px;
                color: #303030;
                margin: 0 8px;
              }

              .num {
                font-weight: 500;
                font-size: 12px;
                color: #7D7D7D;
                width: 114px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

          }
        }
      }

    }

    .content-right {
      width: 450px;
      height: 100%;
      background-color: #FFF;
      margin-left: 13px;
      flex-shrink: 0;
      overflow-y: auto;

      /deep/ .ant-tabs-bar {
        margin: 0;
      }
    }
  }
}
</style>
